export const links = [
  {
    site: "swg",
    text: "Sporky's Blog",
    url: "/swg",
    logo: "/assets/images/silverware_games_logo.png",
  },
  {
    site: "cda",
    text: "Coffee Doodle",
    url: "/cda",
    logo: "/assets/images/cda-link3.png",
  },
  {
    site: "matchyverse",
    text: "Matchyverse",
    url: "/matchyverse",
    logo: "/assets/images/matchyverse_link.webp",
  },
  {
    site: "plzno",
    text: "Michaelplzno",
    url: "/plzno",
    logo: "/assets/images/michaelplzno-small.png",
  },
];
