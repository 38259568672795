/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import EmptyList from "../../components/common/EmptyList";
import BlogList from "../../components/Home/BlogList";
import Header from "../../components/Home/Header";
import BlogItem from "../../components/Home/BlogList/BlogItem";
import { recentPosts as defaultRecentPosts } from "../../config/recentPosts";
import { links } from "../../config/links";
import { Helmet } from "react-helmet";
import "./style.css"; // Ensure this import is correct and placed after other imports

const defaultHeaderInfo = {
  top: "Silverware Games, Inc.",
  title: "We Bring the Fun™",
  tagline1: "Daily serving of free games and art.",
  tagline2: "Be the first to try these undiscovered indie gems!",
  desc1:
    "Welcome to Silverware Games, an indie game dev company serving up new kinds of games. " +
    "Abstract art more your thing? Check out Coffee Doodle Art for our latest drawings. " +
    "Are you more into cute space adventures? Visit the Matchyverse. Or you want to " +
    "laugh at our fearless leader's antics? Check out MichaelPlzNo!",
   desc2:
    "Chow down on a listing of all our work from the last decade right " +
    "up to today! Games, videos, art, drawings, jokes, if it's fun, we bring it!",
  serving: "Spoonfull of Recent Blogs:",
};

const Home = () => {
  const { site } = useParams();

  const [headerInfo, setHeaderInfo] = useState(defaultHeaderInfo);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    setLoading(true);

    async function fetchBlog() {
      try {
        let recentModule = null;
        let listModule;
        if (site) {
          const [firstWord, secondWord] = site.split("-");
          if (secondWord === "archive") {
            listModule = await import(`../../config/${firstWord}/archive`);
            recentModule = null;
          } else {
            listModule = await import(`../../config/${site}/data`);
            recentModule = await import(`../../config/${site}/recent`);
          }

          const { headerInfo, blogList } = listModule;
          setHeaderInfo(headerInfo);
          setBlogs(blogList);
        } else {
          setBlogs([]);
          setHeaderInfo(defaultHeaderInfo);
        }

        if (recentModule) {
          setRecentPosts(
            Array.isArray(recentModule.recentPosts)
              ? recentModule.recentPosts
              : defaultRecentPosts
          );
        } else {
          setRecentPosts(site ? null : defaultRecentPosts);
        }

        setHeaderLogo(null);
        if (site) {
          const link = links.find(
            (link) => link.site === site || link.site === site.split("-")[0]
          );
          if (link) {
            setHeaderLogo(link.logo);
          }
        }
      } catch (error) {
        setBlogs([]);
        setRecentPosts([]);
        setHeaderInfo(defaultHeaderInfo);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    }

    fetchBlog();
  }, [site]);

  useEffect(() => {
    document.title = headerInfo.title;
  }, [headerInfo]);

  return (
    <div>
      <Helmet>
        <title>{headerInfo.title}</title>
        <meta
          name="description"
          content={headerInfo.tagline1 + " " + headerInfo.tagline2}
        />
        <meta property="og:title" content={headerInfo.title} />
        <meta
          property="og:description"
          content={headerInfo.tagline1 + " " + headerInfo.tagline2}
        />
        <meta property="og:image" content={"/assets/images/thumb.jpg"} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={headerInfo.title} />
        <meta
          name="twitter:description"
          content={headerInfo.tagline1 + " " + headerInfo.tagline2}
        />
        <meta name="twitter:image" content={headerInfo.title} />
      </Helmet>

      {loading ||
      ((!blogs || !blogs.length) && (!recentPosts || !recentPosts.length)) ? (
        <EmptyList />
      ) : (!recentPosts || !recentPosts.length) && blogs && blogs.length ? (
        <BlogList blogs={blogs} headerLogo={headerLogo} />
      ) : (
        <>
          <Header headerInfo={headerInfo} />
          <div className="home-main-page">
            <p className="main-spacer-bottom">{headerInfo.desc1}</p>
            <p className="main-spacer">{headerInfo.desc2}</p>
          </div>
          <h2 className="spoonful-header">{headerInfo.serving}</h2>
          <div
            className={
              site ? "recent-posts-grid-four" : "recent-posts-grid-eight"
            }
          >
            {recentPosts.map((post, index) => {
              const link = links.find((link) => link.site === post.site);
              const isNewest = index === 0; // Assuming the first post in recentPosts is the newest
              return (
                <React.Fragment key={index}>
                  <div className="blog-item-wrapper">
                    {isNewest && (
                      <img
                        src="assets/images/new.png"
                        alt="New"
                        className="new-badge"
                      />
                    )}
                    <BlogItem blog={post} index={index} />
                  </div>
                  {!site && (
                    <a href={link.url} className="blog-link-button">
                      <img
                        src={link.logo}
                        alt={`${link.title} logo`}
                        className="blog-link-logo"
                      />
                      {link.title}
                    </a>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {site && (
            <div className="home-pagination-bar">
              <Link to="/" className="pagination-button white-text">
                &larr; Home
              </Link>
              <Link
                to={`/${site}-archive/`}
                className="pagination-button white-text"
              >
                More &rarr;
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Home;
