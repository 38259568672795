/** @format */

import React from "react";
import "./styles.css";

const currentYear = new Date().getFullYear();

const Footer = ({ hitCount = {} }) => {
  const hits = hitCount.hits;
  const global = hitCount.global;
  const globalUMU = hitCount.globalUMU;
  const hit15 = hitCount.hit15;
  const global15 = hitCount.global15;

  return (
    <>
      <div className="footer-spacer"></div>
      <div className="main-footer">
        <div>
          &copy; 2014-{currentYear} Silverware Games, Inc. All rights reserved.
          <br />
          {hits !== undefined &&
          global !== undefined &&
          globalUMU !== undefined ? (
            <>
              Hit15: {hit15} Page: {hits} G15:{global15} Global: {global} UMU:{" "}
              {globalUMU}
            </>
          ) : (
            "Loading hit data..."
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
