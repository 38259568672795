/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlogItem from "./BlogItem";
import "./styles.css";

const BlogList = ({ blogs, headerLogo }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage, setBlogsPerPage] = useState(8);
  const [columns, setColumns] = useState(4);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);

      let newColumns = Math.floor((windowWidth * 0.8) / 500);
      if (newColumns < 1) newColumns = 1;

      let realColumnWidth = (windowWidth * 0.8) / newColumns;

      let newRows = Math.floor(
        ((window.innerHeight - 250) * 0.82) / (realColumnWidth / 1.3)
      );

      if (newRows < 1) {
        newRows = 1;
        newColumns = 1;
      }

      setColumns(newColumns);
      setBlogsPerPage(newColumns * newRows);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth, windowHeight]);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage === 1) {
      navigate("/");
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  return (
    <>
      {headerLogo && columns > 1 && (
        <div className="floating-header">
          <img src={headerLogo} alt="Header" className="header-image" />
        </div>
      )}
      {columns > 1 ? (
        <div className="fullscreen-center">
          <div
            className="blogList-wrap"
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${columns}, 1fr)`,
              gap: "10px", // Add some gap between items
            }}
          >
            {currentBlogs.map((blog, index) => (
              <BlogItem key={blog.id} blog={blog} index={index + 1} />
            ))}
          </div>
        </div>
      ) : (
        <div
          className="blogList-wrap"
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gap: "10px", // Add some gap between items
          }}
        >
          {blogs.map((blog, index) => (
            <BlogItem key={blog.id} blog={blog} index={index + 1} />
          ))}
        </div>
      )}
      {columns > 1 && (
        <div className="pagination-bar">
          <button className="pagination-button" onClick={handlePreviousPage}>
            {currentPage === 1 ? "← Home" : "← Previous"}
          </button>
          {totalPages > 1 &&
            [...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                className={`pagination-button ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                onClick={() => handlePageClick(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          {columns > 1 && indexOfLastBlog < blogs.length && (
            <button className="pagination-button" onClick={handleNextPage}>
              Next →
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default BlogList;
