/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Chip from "../../components/common/Chip";
import EmptyList from "../../components/common/EmptyList";
import "./styles.css";
import { Link } from "react-router-dom";
import CTAButton from "../../components/Blog/CTAButton";
import { fetchBlog } from "../../utils/blogUtils";
import { Helmet } from "react-helmet";

const Blog = () => {
  const { site, id } = useParams();
  const [blog, setBlog] = useState(null);
  const [coverUrl, setCoverUrl] = useState(null);

  useEffect(() => {
    async function loadBlog() {
      console.log("Loading blog for site:", site, "and id:", id); // Debugging log
      const blog = await fetchBlog(site, id);
      setBlog(blog);
      if (blog) {
        if (blog.ctaButtons && blog.ctaButtons.length === 1) {
          setCoverUrl(blog.ctaButtons[0].linkTo);
        } else {
          setCoverUrl(`/${site}`);
        }
      }
    }

    loadBlog();
  }, [site, id]);

  useEffect(() => {
    if (blog) {
      document.title = blog.title;
      const metaImage = document.querySelector('meta[property="og:image"]');
      if (metaImage) {
        metaImage.setAttribute("content", blog.cover);
      } else {
        const newMetaImage = document.createElement("meta");
        newMetaImage.setAttribute("property", "og:image");
        newMetaImage.setAttribute("content", blog.cover);
        document.head.appendChild(newMetaImage);
      }
    }
  }, [blog]);

  return (
    <>
      {blog ? (
        <Helmet>
          <title>{blog.title}</title>
          <meta name="description" content={blog.description} />
          <meta property="og:title" content={blog.title} />
          <meta property="og:description" content={blog.description} />
          <meta property="og:image" content={blog.cover} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={blog.title} />
          <meta name="twitter:description" content={blog.description} />
          <meta name="twitter:image" content={blog.title} />
        </Helmet>
      ) : null}
      {blog ? (
        <div className="blog-wrap">
          <header>
            <p className="blog-date">Published {blog.createdAt}</p>
            <h1>{blog.title}</h1>
            <div className="blog-subCategory">
              {blog.subCategory.map((category, i) => (
                <div key={i}>
                  <Chip label={category} />
                </div>
              ))}
            </div>
          </header>
          {blog.images && blog.images.length > 0 ? (
            <div className="image-grid">
              {blog.images.map((image, index) => (
                <div key={index} className="image-item">
                  <Link className="blog-inspect" to={`/${site}/${id}/${index}`}>
                    <img src={image} alt="CDA ART" />
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <Link className="blog-cover-link" to={coverUrl}>
              <img src={blog.cover} alt="cover" className="blog-wrap-img" />
            </Link>
          )}
          <div
            className="blog-body"
            dangerouslySetInnerHTML={{ __html: blog.fullText }}
          />
          {blog.ctaButtons ? (
            <div className="cta-buttons">
              {blog.ctaButtons.map((button, index) => (
                <CTAButton
                  key={index}
                  platformLogo={button.platformLogo}
                  titleText={button.titleText}
                  priceTag={button.priceTag}
                  appIcon={button.appIcon}
                  linkTo={button.linkTo}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default Blog;
