/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const BlogItem = ({
  blog: {
    description,
    title,
    createdAt,
    authorName,
    authorAvatar,
    cover,
    category,
    id,
    site,
    link,
    duration,
    authorLink,
    authorCta,
  },
  index,
}) => {
  const [fadeIn, setFadeIn] = useState(false);
  const linkto = link ? link : `/${site}/${id}`;

  const delay = `${index * 0.1}s`;

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <Link to={linkto} className="blogItem">
      <div
        className={`blogItem-wrap ${fadeIn ? "fade-in" : ""}`}
        style={{ animationDelay: delay }}
      >
        <div className="blogItem-cover">
          <img src={cover} alt="cover" className="blogItem-image" />
          {duration ? <div className="overlay-text">{duration}</div> : null}
        </div>
        <div className="blogItem-title">
          <h3>{title}</h3>
          {authorCta ? (
            <div className="blogItem-cta-button">Download Now</div>
          ) : (
            <div className="blogItem-author">
              <img src={authorAvatar} alt="avatar" />
              <div>
                <h6>{authorName}</h6>
                <p>{createdAt}</p>
              </div>
            </div>
          )}
        </div>
        <p className="blogItem-desc">{description}</p>
      </div>
    </Link>
  );
};

export default BlogItem;
