export async function fetchBlogList(site) {
  try {
    const listModule = await import(`../config/${site}/data.js`);
    const { blogList } = listModule;
    return blogList;
  } catch (error) {
    console.error("Error loading blog list:", error);
    return [];
  }
}

export async function fetchBlog(site, id) {
  try {
    const blogList = await fetchBlogList(site);
    console.log("Fetched blog list:", blogList); // Debugging log
    const blog = blogList.find((blog) => blog.id === parseInt(id));
    console.log("Fetched blog:", blog); // Debugging log
    return blog || null;
  } catch (error) {
    console.error("Error loading blog:", error);
    return null;
  }
}
