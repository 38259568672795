export function extractImageName(filePath) {
  // Split the file path to get the last part
  const parts = filePath.split("/");
  const encodedName = parts[parts.length - 1];

  // Decode the encoded name
  const decodedName = decodeURIComponent(encodedName);

  // Extract the image name without the date part and remove the .jpg extension
  const nameParts = decodedName.split(" ");
  const imageNameWithExtension = nameParts.slice(1).join(" ");
  const imageName = imageNameWithExtension.substring(0, imageNameWithExtension.lastIndexOf('.'));

  return imageName;
}

export function extractAuthor(filename) {
  const parts = filename.split(/[/.]/);
  if (parts.length > 7) {
    if (parts[7] === "M") {
      return "michaelplzno";
    }
    if (parts[7] === "L") {
      return "Lily Jupiter";
    }
  }

  return "unknown";
}

export function extractDate(filename) {
  // Extract the date part using regex
  const parts = filename.split(/[/.]/);

  if (parts.length > 9) {
    const day = parts[9];
    const month = parts[8];
    const year = "20" + parts[3]; // Assuming the year is in the format YY

    // Create a date object
    const dateObj = new Date(`${year}-${month}-${day}`);

    // Format the date as "Month Day, Year"
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);

    if (formattedDate === "Invalid Date") {
      const day2 = parts[5];
      const month2 = parts[4];
      const year2 = "20" + parts[3]; // Assuming the year is in the format YY

      const dateObj2 = new Date(`${year2}-${month2}-${day2}`);
      const formattedDate2 = dateObj2.toLocaleDateString("en-US", options);
      return formattedDate2;
    }
    return formattedDate;
  } else {
    console.log("Date not found in the filename");
  }

  return null;
}
