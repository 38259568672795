import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { extractImageName, extractAuthor, extractDate } from "../../utils/imageHandler";
import "./style.css";

const Image = () => {
  const { site, id, index } = useParams();
  const navigate = useNavigate();
  const [imageLocation, setImageLocation] = useState(null);
  const [title, setTitle] = useState("");
  const [displayDate, setDisplayDate] = useState(null);
  const [author, setAuthor] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(parseInt(index));
  const [imageCount, setImageCount] = useState(1);

  const handlePrevious = () => {
    let next = currentIndex - 1;
    if (next < 0) {
      next += imageCount;
    }

    setCurrentIndex(next);
    navigate(`/${site}/${id}/${next}`);
  };

  const handleNext = () => {
    let next = currentIndex + 1;
    if (next >= imageCount) {
      next -= imageCount;
    }

    setCurrentIndex(next);
    navigate(`/${site}/${id}/${next}`);
  };

  useEffect(() => {
    async function fetchImage() {
      try {
        const listModule = await import(`../../config/${site}/data.js`);
        const { blogList } = listModule;
        let blog = blogList.find((blog) => blog.id === parseInt(id));
        if (blog && blog.images) {
          setImageCount(blog.images.length);
        }
        if (blog && blog.images && blog.images.length > index) {
          setImageLocation(blog.images[parseInt(index)]);
          setTitle(extractImageName(blog.images[parseInt(index)]));
          setDisplayDate(extractDate(blog.images[parseInt(index)]));
          setAuthor(extractAuthor(blog.images[parseInt(index)]));
        } else {
          setImageLocation("/assets/images/13525-empty.gif");
          setDisplayDate(null);
          setAuthor(null);
          setTitle("Image not found");
          setImageCount(1);
        }
      } catch (error) {
        console.error("Error loading list:", error);
        setImageLocation("/assets/images/13525-empty.gif");
        setTitle("Error loading image");
        setDisplayDate(null);
        setAuthor(null);
        setImageCount(1);
      }
    }

    fetchImage();
  }, [site, id, index]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {imageLocation && <meta property="og:image" content={imageLocation} />}
      </Helmet>
      <div className="header-container">
        {displayDate && author ? (
          <p className="blog-date">
            {displayDate} by {author}
          </p>
        ) : null}
        <h1>{title}</h1>
      </div>
      <div className="image-container">
        {imageLocation && (
          <div className="image-wrapper">
            <img
              src={imageLocation}
              alt={title}
              onClick={handleNext}
              className="main-image"
            />
          </div>
        )}
        <div className="navigation-buttons">
          <button onClick={handlePrevious} className="nav-button">
            ←
          </button>
          <button onClick={handleNext} className="nav-button">
            →
          </button>
        </div>
      </div>
    </>
  );
};

export default Image;
