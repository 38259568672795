import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { links } from "../../../config/links";
import { fetchBlog } from "../../../utils/blogUtils";
import { extractImageName } from "../../../utils/imageHandler";
import "./style.css";

const Navbar = ({ site, id, index }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";
  const [showConfetti, setShowConfetti] = useState(false);
  const pageTitle = document.title;
  const [blog, setBlog] = useState(null);
  const [imageName, setImageName] = useState(null); // State to store the image name
  const navbarRef = useRef(null); // Ref to store the navbar element

  useEffect(() => {
    async function loadBlog() {
      const blog = await fetchBlog(site, id);
      setBlog(blog);
      if (blog && blog.images && index) {
        const imageIndex = parseInt(index, 10);
        if (!isNaN(imageIndex) && blog.images[imageIndex]) {
          setImageName(extractImageName(blog.images[imageIndex]));
        }
      }
    }

    if (site && id) {
      loadBlog();
    }
  }, [site, id, index]);

  useEffect(() => {
    const adjustPadding = () => {
      if (navbarRef.current) {
        document.body.style.paddingTop = `${navbarRef.current.offsetHeight}px`;
      }
    };

    adjustPadding();
    window.addEventListener("resize", adjustPadding);

    return () => {
      window.removeEventListener("resize", adjustPadding);
    };
  }, []);

  const handleHomeClick = () => {
    if (isHomePage) {
      handleEmojiClick();
    } else {
      navigate("/");
    }
  };

  const handleEmojiClick = () => {
    document.body.classList.add("shake");
    setShowConfetti(true);
    setTimeout(() => {
      document.body.classList.remove("shake");
      setShowConfetti(false);
    }, 4000);
  };

  const currentSite = links.find((link) => link.site === site || (site && link.site === site.split("-")[0]));
  const siteTitle = currentSite ? currentSite.text : "Unknown Site";

  return (
    <>
      {showConfetti && <Confetti />}
      <div className="navbar" ref={navbarRef}>
        <span
          className="home-icon"
          onClick={handleHomeClick}
          style={{ cursor: "pointer" }}
        >
          🍴
        </span>
        {!isHomePage && <span className="separator">{">"}</span>}
        <p>
          {site && id ? (
            <a href={`/${site}/`} className="site-link">
              {siteTitle}
            </a>
          ) : (
            site && `${siteTitle}`
          )}
          {site && id && <span className="separator">{">"}</span>}
          {id && index ? (
            blog ? (
              <a href={`/${site}/${id}`} className="blog-link">
                {blog.title}
              </a>
            ) : (
              pageTitle
            )
          ) : (
            id && `${blog ? blog.title : id}`
          )}
          {(site || id) && index && <span className="separator">{">"}</span>}
          {imageName && <span className="image-name">{imageName}</span>}{" "}
          {/* Display the image name if available */}
        </p>
      </div>
    </>
  );
};

export default Navbar;
